let STRIPE = Stripe(document.querySelector("meta[name='stripe_public_key']").content);
$(document).ready(function () {
  //- Custom Two Way Toggle Switch Active Class on Click
  // $('#purchase_type').on('click', function(event){

  // });

  //- Remove Modal Backdrop
  $('.modal').appendTo("body");

  // Home Banner Slides
  $('#meal_menu_item').on('click', function(){
      $(this).addClass('am-home__meal-active').siblings().removeClass("am-home__meal-active");
      $('#meal_menu_content').addClass('am-home__meal-content--anim').show();
      $('#meal_packages_content').removeClass('am-home__meal-content--anim').hide();
      $('#lets_eat_content').removeClass('am-home__meal-content--anim').hide();
  });
  $('#meal_packages_item').on('click', function(){
      $(this).addClass('am-home__meal-active').siblings().removeClass("am-home__meal-active");
      $('#meal_menu_content').removeClass('am-home__meal-content--anim').hide();
      $('#meal_packages_content').addClass('am-home__meal-content--anim').show();
      $('#lets_eat_content').removeClass('am-home__meal-content--anim').hide();
  });
  $('#lets_eat_item').on('click', function(){
      $(this).addClass('am-home__meal-active').siblings().removeClass("am-home__meal-active");
      $('#meal_menu_content').removeClass('am-home__meal-content--anim').hide();
      $('#meal_packages_content').removeClass('am-home__meal-content--anim').hide();
      $('#lets_eat_content').addClass('am-home__meal-content--anim').show();
  });


  // Home Trust Slider
  if ($('.am-home__trust-container')[0]) {
      var trust_swiper = new Swiper(".am-home__trust-container", {
          slidesPerView: 'auto',
          keyboard: true,
          autoplay: {
              delay: 3500,
              disableOnInteraction: true,
          },
          pagination: {
              el: '.am-home__trust-pagination',
              clickable: true
          },
      });
  }

  // Home Testimonials Slider
  if ($('.am-home__testimonials-container')[0]) {
    var testimonials_swiper = new Swiper(".am-home__testimonials-container", {
      slidesPerView: 1,
      spaceBetween: 30,
      mousewheel: true,
      keyboard: true,
      effect: 'cube',
      cubeEffect: {
        shadow: true,
        slideShadows: true,
        shadowOffset: 10,
        shadowScale: 0.4,
      },
      grabCursor: true,
      autoplay: {
        delay: 3500,
        disableOnInteraction: true,
      },
      pagination: {
        el: '.am-home__testimonials-pagination',
        clickable: true
    },
      navigation: {
        nextEl: '.am-home__testimonials-navigations--next',
        prevEl: '.am-home__testimonials-navigations--prev',
      },
    });
  }

  // About Team Slider
  if ($('.am-about__team-container')[0]) {
      var team_swiper = new Swiper(".am-about__team-container", {
          slidesPerView: 'auto',
          spaceBetween: 30,
          mousewheel: true,
          keyboard: true,
          centeredSlides: true,
          autoplay: {
              delay: 3500,
              disableOnInteraction: true,
          },
          pagination: {
              el: '.am-about__team-pagination',
              clickable: true
          },
          navigation: {
              nextEl: '.am-about__team-navigations--next',
              prevEl: '.am-about__team-navigations--prev',
          },
      });
  }

  //- Cart Add Address Show/Hide on Click in Cart Page
  $('#cart_add_address').on('click', function(){
      $('#cart_add_address_show').toggle('1000');
  });


  //- Delivery Preferences in My Order Detail Page
  $('#address_delivery_prefernce').on('click', function(){
      $('#address_delivery_address_show').show('1000');
      $('#customer_pickup_address_show').hide('1000');
      $('#cart_add_address').show('1000');
  });
  $('#customer_pickup_preference').on('click', function(){
      $('#customer_pickup_address_show').show('1000');
      $('#address_delivery_address_show').hide('1000');
      $('#cart_add_address').hide('1000');
      $('#cart_add_address_show').hide('1000');
  });




  // ===== Viewport & animations ===== //
  gsap.utils.toArray('.am-anim__wrap').forEach(function(elem) {

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: elem,
          start: 'top 60%',
        }
      });

      var item_stagger = elem.querySelectorAll(".am-anim__stagger-item");
      var item_stagger_x = elem.querySelectorAll(".am-anim__stagger-item--x");
      var item_textMove = elem.querySelectorAll(".am-anim__text-move");
      var item_textMoveImg = elem.querySelector(".am-anim__text-move + img");
      var item_fadeIn = elem.querySelectorAll(".am-anim__fade-in");
      var item_fadeInLeft = elem.querySelectorAll(".am-anim__fade-in-left");
      var item_fadeInRight = elem.querySelectorAll(".am-anim__fade-in-right");
      var item_fadeInTop = elem.querySelectorAll(".am-anim__fade-in-top");
      var item_fadeInBottom = elem.querySelectorAll(".am-anim__fade-in-bottom");
      var item_fadeScale = elem.querySelectorAll(".am-anim__fade-scale");
      var item_staggerText = elem.querySelectorAll(".am-anim__stagger-item--text");
      var item_noAnim = elem.querySelectorAll(".am-anim__no-anim--hero");


      // Global
      tl.to(item_stagger, {opacity: 1, y:0, stagger: 0.2})
      tl.to(item_textMove, {opacity: 1, y: 0}, "-=0.6")
      tl.to(item_fadeIn, {duration: 0.4, opacity: 1, ease: "Expo.ease"})
      tl.to(item_stagger_x, {opacity: 1, x:0, stagger: 0.1}, "0")
      tl.to(item_staggerText, {duration: 0.4, opacity: 1, y: 0, stagger: 0.03}, "0")


      tl.to(item_fadeInTop, {duration: 0.4, opacity: 1, y: 0, ease: "Expo.ease"}, "0")
      tl.to(item_fadeInBottom, {duration: 0.4, opacity: 1, y: 0, ease: "Expo.ease"}, "0")
      tl.to(item_fadeInLeft, {duration: 0.4, opacity: 1, x: 0, ease: "Expo.ease"}, "0")
      tl.to(item_fadeInRight, {duration: 0.4, opacity: 1, x: 0, ease: "Expo.ease"}, "0")
      tl.to(item_fadeScale, {duration: 0.4, opacity: 1, scale: 1, x: 0, ease: "Expo.ease"}, "0")

      tl.to(item_noAnim, {duration: 0, opacity: 1, delay: 0.3}, "0")

  });


  // parallax animation
  gsap.utils.toArray('.am-anim__wrap').forEach(function(elem) {

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: elem,
          scrub: true
        }
      });

      var item_scale_img = elem.querySelector(".am-anim__scale-img--front");
      var item_scale_img_back = elem.querySelectorAll(".am-anim__scale-img--back");
      var item_parallax_bwn_lft = elem.querySelectorAll(".am-anim__parallax-bwn--left");
      var item_parallax_bwn_rgt = elem.querySelectorAll(".am-anim__parallax-bwn--right");
      var item_parallax_rgt = elem.querySelectorAll(".am-anim__parallax-img--right");
      var item_parallax_lft = elem.querySelectorAll(".am-anim__parallax-img--left");
      var item_parallax_rgt = elem.querySelectorAll(".am-anim__parallax-img--right");
      var item_parallax_top_left = elem.querySelectorAll(".am-anim__parallax-top--left");
      var item_parallax_top_right = elem.querySelectorAll(".am-anim__parallax-top--right");
      var item_parallax_small_bottom = elem.querySelectorAll(".am-anim__parallax-small--bottom");

      tl.to(item_scale_img, {
        scrollTrigger: {
          start: 'top bottom'
        }, duration: 0.4, y: 0}, "0")

      tl.to(item_scale_img_back, {
        scrollTrigger: {
          start: "top bottom"
        }, y: 0, opacity: 1, scale: 1, ease: "Expo.ease"}, "0")

      tl.to(item_parallax_bwn_lft, {
          scrollTrigger: {
            start: "left bottom "
          }, x: 0, opacity: 1, scale: 1, ease: "Expo.ease"
        }, "0")

      tl.to(item_parallax_bwn_rgt, {
          scrollTrigger: {
            start: "top right"
          }, x: 0, opacity: 1, scale: 1, ease: "Expo.ease"
        }, "0")

      tl.to(item_parallax_lft, {
          scrollTrigger: {
            start: "left bottom "
          }, x: 0, opacity: 1, scale: 1, ease: "Expo.ease"
        }, "0")

      tl.to(item_parallax_rgt, {
          scrollTrigger: {
            start: "left bottom"
          }, x: 0, opacity: 1, scale: 1, ease: "Expo.ease"
        }, "0")

      tl.to(item_parallax_top_left, {
          scrollTrigger: {
            start: "top bottom"
          }, y: 0, x: 0, ease: "Expo.ease"}, "0")

      tl.to(item_parallax_top_right, {
          scrollTrigger: {
            start: "top bottom"
          }, y: 0, x: 0, ease: "Expo.ease"}, "0")

      tl.to(item_parallax_small_bottom, {
          scrollTrigger: {
            start: "top bottom"
          }, y: 0, ease: "Expo.ease"}, "0")

  });

   //- Work Process
   gsap.utils.toArray('.am-anim__wrap-process').forEach(function (elem) {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: elem,
        start: 'top 49%',
      }
    });

    var item_stagger_processA = elem.querySelectorAll(".am-anim__stagger-process--A");
    var item_stagger_processB = elem.querySelectorAll(".am-anim__stagger-process--B");
    var item_stagger_processC_left = elem.querySelectorAll(".am-anim__stagger-process--C-left");
    var item_stagger_processC_right = elem.querySelectorAll(".am-anim__stagger-process--C-right");
    var item_stagger_processD = elem.querySelectorAll(".am-anim__stagger-process--D");

    tl.to(item_stagger_processA, { duration: 0.2, scale: 1, opacity: 1}, "0.1");
    tl.to(item_stagger_processB, { duration: 0.2, scale: 1, opacity: 1});
    tl.to(item_stagger_processC_left, { duration: 0.2, opacity: 1, x: 10 }, "0");
    tl.to(item_stagger_processC_right, { duration: 0.2, opacity: 1, x: 10 }, "0");
    tl.to(item_stagger_processD, { duration: 0.2, x: 0, opacity: 1 });

   });

  //- SVG Path Line animation
  gsap.utils.toArray('.am-anim__wrap').forEach(function (elem) {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: elem,
        start: 'top 60%',
        end: '60% top',
        scrub: true,
        once: true,
      }
    });

    var svg_line = elem.querySelectorAll("#dashedPath");
    tl.to(svg_line, {
      strokeDashoffset: "0",
      fill: '#a9ca41',
      duration: 5,
      delay: 2
    }, "0");

  });

  //For Header scroll to hide and show
  var header_height = $('.am-header').innerHeight();
  var actionNav = gsap.to('.am-header', {y: -header_height, duration:0.3, paused:true});

  ScrollTrigger.create({
    trigger: ".am-header",
    start: "10px top",
    end: 99999,
    // onEnter: () => {},
    onUpdate: ({progress, direction, isActive}) => {
      if (direction == -1) {
        actionNav.reverse()
      } if (direction == 1 ) {
        actionNav.play()
      } else if (direction == 1 && isActive == true) {
        actionNav.play()
      }
    }
  });

  gsap.to('.am-header__light', {
    scrollTrigger: {
      trigger: ".am-header__light",
      start: "60px top",
      toggleActions: "restart none reset none",
    }, boxShadow: "rgba(202, 202, 202, 0.20) 0px 0px 10px 10px",
    background: "#ffffff",
    zIndex:'250',
  });

  gsap.to('.am-header__dark', {
    scrollTrigger: {
      trigger: ".am-header__dark",
      start: "60px top",
      toggleActions: "restart none reset none"
    }, boxShadow: "rgba(202, 202, 202, 0.20) 0px 0px 10px 10px",
    backgroundColor: "#A9CA41",
  });

});


//Lazy Loading Images with gsap : Start
window.addEventListener("DOMContentLoaded", function () {
  var lazy = document.getElementsByClassName("lazy");
  initializeLoad(lazy);
});
function initializeLoad(imgs) {
  if (imgs.length > 0) {
    if ("IntersectionObserver" in window) {
      lazyLoad(imgs);
    } else {
      loadIntersection(imgs);
    }
  } else {
    return;
  }
}
function lazyLoad(lazy) {
  var options = {
    threshold: 0.5
  };
  var observer = new IntersectionObserver(function (entries, self) {
    for (var i = 0; i < entries.length; i++) {
      var entry = entries[i];
      if (entry.isIntersecting) {
        var src = entry.target.getAttribute("data-src");
        gsap.set(entry.target, { attr: { src: src } });
        gsap.fromTo(
          entry.target,
          1,
          { css: { opacity: 0, y: -8 } },
          { css: { opacity: 1, y: 0 } },
          1.5
        );
        self.unobserve(entry.target);
      }
    }
  }, options);
  for (var i = 0; i < lazy.length; i++) {
    var lazyItem = lazy[i];
    observer.observe(lazyItem);
  }
}
function loadIntersection(lazy) {
  var io = document.createElement("script");
  io.src =
    "https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver";
  document.head.appendChild(io);
  return (io.onload = function () {
    lazyLoad(lazy);
  });
}
//Lazy Loading Images with gsap : End

toastr.options = {
  closeButton: true,
  // onHidden: () => {
  //   if (isPrevented) {
  //     return false;
  //   } else {
  //     //Sent result to server;
  //   }
  // },
  onCloseClick: () => {
    isPrevented = true
  }
}